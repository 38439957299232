<template>
  <div class="p-grid p-justify-center">
    <div class="card p-lg-6 p-md-8 p-sm-12 p-xs-12">
      <p>Модель: {{ technicModel?.name }}</p>
      <p>Тип: {{ technicModel?.technicType.name }}</p>
      <p>Производитель: {{ technicModel?.technicProducer?.name }}</p>
      <div class="p-mt-3 p-field">
        <DataTable
          :value="checklistsTemplates"
          class="p-datatable-customers p-datatable-striped"
          dataKey="id"
          :rows="10"
          :rowHover="true"
          sort-field="typeForSort"
          :sort-order="1"
          :autoLayout="true"
          selectionMode="single"
          :loading="isLoading"
        >
          <template #empty>Список чеклистов пуст</template>
          <template #loading>Загрузка...</template>
          <Column field="typeForSort" header="Тип работ" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.typeForSort }}
            </template>
          </Column>
          <Column field="name" header="Название чеклиста" :sortable="true">
            <template #body="slotProps"> {{ slotProps.data.name }} </template>
          </Column>
          <Column field="normalHours" header="Нормо-часы" style="width: 300px">
            <template #body="slotProps">
              <div class="p-d-flex p-align-center">
                <div v-if="editId === slotProps.data.id">
                  <InputText
                    v-model="hours"
                    class="p-mr-1"
                    :class="hoursIsValid === true ? '' : 'p-invalid'"
                  />
                  <span class="p-mr-4">ч.</span>

                  <InputText
                    v-model="minutes"
                    class="p-mr-1"
                    :class="minutesIsValid === true ? '' : 'p-invalid'"
                  />
                  <span class="p-mr-4">мин.</span>

                  <Button
                    class="p-mr-2"
                    icon="pi pi-check"
                    @click="sendNormalHours(slotProps.data)"
                    title="Сохранить нормо-часы"
                  />

                  <Button
                    @click="turnOffEditMode()"
                    icon="pi pi-times"
                    class="p-button-danger"
                    title="Отменить редактирование"
                  />
                </div>
                <div
                  v-else
                  class="p-d-flex p-justify-between p-align-center"
                  style="width: 100%"
                >
                  <span>{{ minutesToDateString(slotProps.data.time) }}</span>
                  <div>
                    <Button
                      class="p-button-warning p-mr-4"
                      icon="pi pi-pencil"
                      @click="
                        turnOnEditMode(slotProps.data.id, slotProps.data.time)
                      "
                      title="Редактировать"
                    />
                    <Button
                      class="p-button-danger"
                      icon="pi pi-trash"
                      @click="clearNormalHours(slotProps.data.normalHourId)"
                      title="Очистить"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";

export default {
  name: "StandardHours",
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      technicModel: null,
      isLoading: false,
      checklistsTemplates: [],
      hours: 0,
      minutes: 0,
      editId: null,
      hoursIsValid: true,
      minutesIsValid: true,
    };
  },
  async mounted() {
    await this.getTechnicModelByUid();
    await this.loadTemplates();
  },
  computed: {
    checklistTypes() {
      return this.$store.getters["data/workTypes"];
    },
  },
  methods: {
    async clearNormalHours(id) {
      this.isLoading = true;

      try {
        await fetch(`${environment.apiUrl}/normal_hours/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          method: "DELETE",
        });
        await this.getTechnicModelByUid();
        await this.loadTemplates();
      } catch (err) {
        console.log(err);
      }
    },
    validateTime() {
      this.hoursIsValid = true;
      this.minutesIsValid = true;

      if (this.hours < 0) {
        this.hoursIsValid = false;
      }

      if (this.minutes < 0 || this.minutes > 59) {
        this.minutesIsValid = false;
      }

      return this.hoursIsValid && this.minutesIsValid;
    },
    minutesToDateString(time) {
      if (!time) {
        return `-`;
      }
      return `${Math.floor(time / 60)} ч. ${time % 60} мин.`;
    },
    turnOnEditMode(id, time) {
      this.editId = id;
      this.hours = time !== undefined ? Math.floor(time / 60) : 0;
      this.minutes = time !== undefined ? time % 60 : 0;
    },
    turnOffEditMode() {
      this.editId = null;
      this.hours = 0;
      this.minutes = 0;
    },
    async sendNormalHours(data) {
      if (!this.validateTime()) return;
      this.isLoading = true;
      let method = "POST";
      let url = `${environment.apiUrl}/normal_hours`;

      const payload = {
        checklistTemplateId: data.id,
        id: null,
        technicModelUid: this.uid,
        time: Number(this.hours) * 60 + Number(this.minutes),
      };

      if (data.normalHourId) {
        method = "PUT";
        url = `${environment.apiUrl}/normal_hours/${data.normalHourId}`;
        payload.id = data.normalHourId;
      }

      try {
        await fetch(url, {
          method: method,
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        await this.getTechnicModelByUid();
        await this.loadTemplates();
        this.turnOffEditMode();
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
    async getTechnicModelByUid() {
      this.isLoading = true;
      try {
        const response = await fetch(
          `${environment.apiUrl}/technic_models/${this.uid}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.technicModel = await response.json();
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка",
          detail: err,
          life: 6000,
        });
      }
    },
    async loadTemplates() {
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/checklist_templates`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.checklistsTemplates = await response.json();

        let map = new Map();

        for (const normalHour of this.technicModel.normalHours) {
          map.set(normalHour.checklistTemplateId, normalHour);
        }
        this.checklistsTemplates = this.checklistsTemplates.map((template) => {
          return {
            ...template,
            typeForSort: this.checklistTypes.find(
              (t) => t.value === template.type
            ).name,
            time: map.get(template.id)?.time,
            normalHourId: map.get(template.id)?.id,
          };
        });
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.p-inputtext {
  max-width: 60px;
}
.p-button {
  width: auto;
  padding: 4px 4px 4px 4px;
}
</style>
